@keyframes bounceLoopDOWN {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(10px);
  }
}

@keyframes bounceLoopUP {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

.paper__top--animate {
  animation: bounceLoopUP 3.5s infinite ease;
  animation-delay: 1.5s;
}

.paper__bottom--animate {
  animation: bounceLoopDOWN 3.5s infinite ease;
  animation-delay: 1.5s;
}

@keyframes drawLine {
  0% {
    stroke-dashoffset: 198;
  }
  100% {
    stroke-dashoffset: 1;
  }
}

.paper__tear {
  stroke-dasharray: 198;
  stroke-dashoffset: 0;
  animation: drawLine 1.5s ease-in-out forwards;
}
