@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#app,
#app > div {
  height: 100%;
}

body {
  background-size: 100%;
  /* background-image: repeating-linear-gradient(
    to bottom right,
    #e1e3e7,
    #ffffff
  ); */

  background-attachment: fixed;
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiToolbar-root,
.MuiTypography-root,
.MuiSelect-icon {
  color: rgb(0, 0, 0);
}

/* Dark Theme Styles */
body.dark {
  background-color: #ffffff;
  background-image: repeating-linear-gradient(
    to bottom right,
    #1d2836,
    #121827
  );
}

body.dark .MuiToolbar-root,
body.dark .MuiTypography-root,
body.dark .MuiSelect-icon {
  color: #ffffff;
}

[data-automationid="malwareDownloadCommand"] {
  display: none !important;
}
